const apiEndpoint = 'https://api.tabulara.org'
const userToken = JSON.parse(localStorage.getItem('user'))
const httpHeaders = {
	headers: {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
		Authorization: userToken,
	},
}

export const GetDir = async id => {
	const dir = await fetch(`${apiEndpoint}/docs/directory/${id}`, {
		method: 'GET',
		headers: httpHeaders.headers,
	})

	return await dir.json()
}

export const GetDirs = async (parentId = null) => {
	const url = parentId
		? `${apiEndpoint}/docs/directory?parentId=${parentId}`
		: `${apiEndpoint}/docs/directory`
	const dirs = await fetch(url, {
		method: 'GET',
		headers: httpHeaders.headers,
	})

	return await dirs.json()
}

export const CreateDir = async form => {
	const details = {
		directory: {
			name: form.name,
			parentId: form.parentId,
			sharedWith: form.sharedWith,
		},
	}

	const newDir = await fetch(`${apiEndpoint}/docs/directory/create`, {
		method: 'POST',
		headers: httpHeaders.headers,
		body: JSON.stringify(details),
	})

	if (newDir.ok) {
		return await newDir.json()
	}
}

export const DeleteDir = async id => {
	// Specify folder or document
	const url = `${apiEndpoint}/docs/directory/${id}/delete`
	// const url = `${apiEndpoint}/docs/document/${id}/delete`

	const dir = await fetch(url, {
		method: 'DELETE',
		headers: httpHeaders.headers,
	})

	return await dir.json()
}

export const GetDoc = async id => {
	const doc = await fetch(`${apiEndpoint}/docs/document/${id}`, {
		method: 'GET',
		headers: httpHeaders.headers,
	})

	return await doc.json()
}

export const GetDocs = async (parentId = null) => {
	const url = parentId
		? `${apiEndpoint}/docs/document?parentId=${parentId}`
		: `${apiEndpoint}/docs/document`

	const docs = await fetch(url, {
		method: 'GET',
		headers: httpHeaders.headers,
	})

	return await docs.json()
}

// Not yet tested - may need some rewriting
export const CreateDoc = async form => {
	const details = {
		document: {
			name: form.name,
			parentId: form.parentId,
			sharedWith: form.sharedWith,
		},
	}

	const newDoc = await fetch(`${apiEndpoint}/docs/document/create`, {
		method: 'POST',
		headers: httpHeaders.headers,
		body: JSON.stringify(details),
	})

	if (newDoc.ok) {
		return await newDoc.json()
	}
}

export const GetSharedDirs = async (parentId = null) => {
	const url = parentId
		? `${apiEndpoint}/docs/directory/shared?parentId=${parentId}`
		: `${apiEndpoint}/docs/directory/shared`

	const sharedDirs = await fetch(url, {
		method: 'GET',
		headers: httpHeaders.headers,
	})

	return await sharedDirs.json()
}

export const GetSharedDocs = async (parentId = null) => {
	const url = parentId
		? `${apiEndpoint}/docs/document/shared?parentId=${parentId}`
		: `${apiEndpoint}/docs/document/shared`

	const sharedDocs = await fetch(url, {
		method: 'GET',
		headers: httpHeaders.headers,
	})

	return await sharedDocs.json()
}
