import React from 'react'
import Icon from './../Icon'
import { useStateValue } from '../../../utils/state/state';
import { TextArea } from 'grommet'
import './style.css'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const WriteTab = ({ sendType }) => {
  const [{ docs }, dispatch] = useStateValue();
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const textAreaHeight = windowDimensions.height - 135;

  let value;
  if (docs.liveContent) {
    value = docs.liveContent
  } else {
    value = docs.currentDocument.content
  }

  const onType = (text) => {
    dispatch({
      type: 'SET_LIVE_CONTENT',
      liveContent: text,
    })
    sendType(text)
  }

  return (
    <div className="writeTab">
      <TextArea
          style={{ height: textAreaHeight + 'px' }}
          placeholder="type heree"
          value={value}
          plain={ true }
          spellCheck={ true }
          onChange={event => onType(event.target.value)}
      />
    </div>
  )
}

export default WriteTab;
