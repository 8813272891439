import React from 'react'
import { Box, Grommet } from 'grommet'
import theme from './utils/Theme/theme'
import { StateProvider } from './utils/state/state'
import mainReducer from './utils/reducers/'
import AppHandler from './AppHandler'

const App = () => {
	const initialState = {
		user: {
			loggedIn: false,
		},
		docs: {},
	}

	return (
		<StateProvider initialState={initialState} reducer={mainReducer}>
			<Grommet theme={theme}>
				<Box height='100vh'>
					<AppHandler />
				</Box>
			</Grommet>
		</StateProvider>
	)
}

export default App
