const apiEndpoint = 'https://api.tabulara.org'

export const UserLogin = async details => {
	const login = async () => {
		return fetch(`${apiEndpoint}/auth/login`, {
			method: 'POST',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(details),
		})
	}

	const res = await login()
	if (res.ok) {
		const userData = await res.json()
		localStorage.setItem('user', JSON.stringify(userData.token))
		console.log(userData)
		window.location.replace('/panel')
		return userData
	} else {
		return await res.json()
	}
}

export const UserRegister = async details => {
	const register = async () => {
		return fetch(`${apiEndpoint}/auth/register`, {
			method: 'POST',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(details),
		})
	}

	const res = await register()
	if (res.ok) {
		const { token } = await res.json()
		localStorage.setItem('user', JSON.stringify(token))
		window.location.replace('/panel')
	} else {
		return await res.json()
	}
}

export const LoadUser = async token => {
	console.log(token)
	const loadUser = await fetch(`${apiEndpoint}/auth/user`, {
		method: 'GET',
		headers: {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
			Authorization: token,
		},
	})

	if (loadUser.ok) {
		return await loadUser.json()
	} else {
		return new Error('Cannot get user')
	}
}

export const CreateInvite = async () => {
	const create = async () => {
		return fetch(`${apiEndpoint}/invite/create`, {
			method: 'POST',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
			},
		})
	}

	const res = await create()
	if (res.ok) {
		return await res.json()
	}
}

export const CheckInvite = async code => {
	const validate = async () => {
		return fetch(`${apiEndpoint}/invite/check`, {
			method: 'POST',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(code),
		})
	}

	const res = await validate()
	return await res.json()
}

export const GetUserByUsername = async username => {
	const check = async () => {
		return fetch(`${apiEndpoint}/auth/user?username=${username}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				Authorization: JSON.parse(localStorage.getItem('user')),
			},
		})
	}

	const res = await check()

	if (res.ok) {
		return await res.json()
	}
}
