const docsReducer = (state, action) => {

    switch (action.type) {
      case 'LOAD_DOCUMENT':
        return {
          ...state,
          currentDocument: action.document,
        };
      break;
      case 'SWITCH_EDITOR_TAB':
        return {
          ...state,
          tab: action.tab,
        }
      break;
      case 'SET_ONLINE_USERS':
        return {
          ...state,
          onlineUsers: action.onlineUsers,
        };
      break;
      case 'SET_LIVE_CONTENT':
        return {
          ...state,
          liveContent: action.liveContent,
        }
      break;
      default:
        return state;
    }
  };

  export default docsReducer;
