import React, { useState } from 'react'
import Button from '../../presentations/Button'
import FormField from '../../presentations/FormField'
import { UserRegister } from '../../../utils/API/AuthApi'
import { Link } from 'react-router-dom'
import Loading from '../../presentations/Loading'
import TimezoneSelect from '../../presentations/TimezoneSelect'
import ErrorText from '../../presentations/ErrorText'
import SuccessText from '../../presentations/SuccessText'
import './style.scss'
import Meter from '../../presentations/Meter'

const PASSWORD_REGEX = /^(?=.{8,})(?=.*[A-Z])(?=.*[-!$%^&*()_+|#@~=`{}[\]:";'<>?,./]).*$/
const defaultValue = {
	username: '',
	first: '',
	last: '',
	preferred: '',
	password: '',
	confirmPassword: '',
	timezone: ''
}

const StageHeading = ({ children }) => <h2 className="stage-heading">{children}</h2>

const Register = ({ inviteCode }) => {
	const [ errorValue, setErrorValue ] = useState()
	const [ loadingValue, setLoadingValue ] = useState(false)
	const [ meterValue, setMeterValue ] = useState(0)
	const [ formValue, setFormValue] = useState(defaultValue)

	const updateForm = e => {
		setFormValue({ ...formValue, [e.target.name]: e.target.value })
	}

	const onSubmit = async e => {
		e.preventDefault()
		setLoadingValue(true)
		setErrorValue()

		const { username, first, last, preferred, password, timezone } = formValue
		const form = {
			username,
			password,
			name: {
				first,
				last,
				preferred
			},
			timezone,
			inviteCode
		}

		const registerCall = await UserRegister(form)
		setLoadingValue(false)
		if (registerCall) {
			setErrorValue(registerCall.message)
		}
	}

	return (
		<div className="register-container">
			
			{/* Progress meter */}
			<div className="progress-meter">
				<img src="logo500transtight.png" />
				<Meter value={meterValue} />
			</div>

			<div className="register-form-container">
				{/* Names */}
				{meterValue === 0 &&
					<div>
						<StageHeading>What's your name?</StageHeading>
						<div className="first-stage">
							<div className="register-fields">
								<FormField className="register-field" name="first" placeholder="First name" value={formValue.first} onChange={updateForm} />
								<FormField className="register-field" name="last" placeholder="Last name" value={formValue.last} onChange={updateForm} />
							</div>
							<div className="register-fields">
								<span className="nickname-hint">
									If you have a nickname or preferred name rather than your legal first name use the field below.
								</span>
								<FormField className="register-field" name="preferred" placeholder="Preferred name" value={formValue.preferred} onChange={updateForm} />
							</div>
						</div>

						<div className="register-buttons">
							<Button
									buttonType="primary"
									onClick={() => setMeterValue(33)}
									disabled={!formValue.first || !formValue.last}>Next</Button>
							<Link to="login">
								<span className="register-back-button">Go back</span>
							</Link>
						</div>
					</div>
				}

				{/* Timezone */}
				{meterValue === 33 &&
					<div className="second-stage">
						<StageHeading>What time is it for you?</StageHeading>

						<div className="timezone-selector-container">
							<TimezoneSelect className="timezone-selector" timezone={formValue.timezone} updateForm={updateForm} />
						</div>

						<div className="register-buttons">
							<Button
								buttonType="primary"
								onClick={() => setMeterValue(66)}
								disabled={!formValue.timezone}>Next</Button>
							<span className="register-back-button" onClick={() => setMeterValue(0)}>Go back</span>
						</div>
					</div>
				}

				{/* Account Details */}
				{meterValue === 66 &&
					<div className="third-stage">
						<StageHeading>Let's create your account</StageHeading>

						<form onSubmit={onSubmit}>
							<div className="register-fields">
								<FormField className="register-field" name="username" placeholder="Username" value={formValue.username} onChange={updateForm} />
								<FormField className="register-field" name="password" type="password" placeholder="Password" value={formValue.password} onChange={updateForm} />
								<FormField className="register-field" name="confirmPassword" type="password" placeholder="Confirm password" value={formValue.confirmPassword} onChange={updateForm} />
							</div>

							{/* Requirements */}
							<div className="register-requirements">
								{(formValue.password || formValue.confirmPassword) &&
									<div>
										{formValue.password === formValue.confirmPassword
											? <SuccessText>✓ Passwords match</SuccessText>
											: <ErrorText>× Passwords do not match</ErrorText>
										}
										{(/[-!$%^&*()_+|#@~=`{}[\]:";'<>?,./]/.test(formValue.password)) || (/[-!$%^&*()_+|#@~=`{}[\]:";'<>?,./]/.test(formValue.confirmPassword))
											? <SuccessText>✓ Symbol</SuccessText>
											: <ErrorText>× Symbol</ErrorText>
										}
										{(/[A-Z]/.test(formValue.password)) || (/[A-Z]/.test(formValue.confirmPassword))
											? <SuccessText>✓ Capital letter</SuccessText>
											: <ErrorText>× Capital letter</ErrorText>
										}
										{(formValue.password.length >= 8) || (formValue.confirmPassword.length >= 8)
											? <SuccessText>✓ At least 8 characters</SuccessText>
											: <ErrorText>× At least 8 characters</ErrorText>
										}

										{errorValue && <ErrorText>× {errorValue}</ErrorText>}
									</div>
								}

								<div className="loading-spinner">
									{loadingValue && <Loading/>}
								</div>
							</div>

							<div className="register-buttons">
								<Button
									type="submit"
									buttonType="primary"
									disabled={
										!formValue.username ||
										!formValue.password ||
										(formValue.password !== formValue.confirmPassword) ||
										(!PASSWORD_REGEX.test(formValue.password))
									}>Finish setup</Button>
								<span className="register-back-button" color="#605c5c" onClick={() => setMeterValue(33)}>Go back</span>
							</div>
						</form>
					</div>
				}
			</div>
		</div>
	)
}

export default Register