import React from 'react'
import './style.scss'

const Meter = ({ value }) => {
  const CALCULATED_WIDTH = (window.innerWidth / 100) * 65

  return (
    <div className="meter-container">
      <svg width={CALCULATED_WIDTH} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect className="bar-one" width={CALCULATED_WIDTH} rx="5" />
        <rect className="bar-two" width={(value / 100) * CALCULATED_WIDTH} rx="5" />
      </svg>
    </div>
  )
}

export default Meter 