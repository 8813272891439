import React, { useState } from 'react'
import { Box } from 'grommet'
import FormField from '../FormField'
import { GetUserByUsername } from '../../../utils/API/AuthApi'
import ErrorText from '../ErrorText'
import { useStateValue } from '../../../utils/state/state'
import Button from '../Button'
import './style.scss'
import Icon from '../Icon'

const InviteUsers = ({ setUsers }) => {
	const [ inviteError, setInviteError ] = useState(null)
	const [ searchValue, setSearchValue ] = useState('')
	const [ selectedUsers, setSelectedUsers ] = useState([])
	const [ permIndex, setPermIndex ] = useState()
	const [{ user }] = useStateValue();

	const getUser = async (e) => {
		e.preventDefault()
		setInviteError(null)

		// Users cannot add themselves
		if (searchValue === user.username) { return setInviteError('Cannot invite yourself') }

		// Check if user is already on list
		const exists = user => user.username === searchValue
		if (selectedUsers.some(exists)) { return setInviteError('User already invited') }

		// GET user
		const gotUser = await GetUserByUsername(searchValue)

		if (gotUser) {
			console.log(gotUser)
			const selectedArr = [...selectedUsers, { userId: gotUser.user.id, username: gotUser.user.username, read: true, write: false }]

			setSelectedUsers(selectedArr)
			setSearchValue('')
			setUsers(selectedArr)
		} else {
			setInviteError('Could not find user')
		}
	}

	const removeUser = index => {
		const newArr = selectedUsers.slice()
		newArr.splice(index, 1)

		setSelectedUsers(newArr)
		setUsers(newArr)
	}

	const togglePermsDrop = index => {
		if (permIndex === index) {
			setPermIndex()
		} else {
			setPermIndex(index)
		}
	}

	const onChangePerms = (perm, index) => {
		const newArr = selectedUsers
		const selectedUser = newArr[index]
		selectedUser.write = perm === 'write'

		newArr.splice(index, 1, selectedUser)

		setPermIndex()
		setSelectedUsers(newArr)
		setUsers(newArr)
	}

	return (
		<Box>
			{/* Searchbar */}
			<Box margin={{ bottom: 'medium' }}>
				<span className="invite-heading">INVITE USERS</span>
				<div className="invite-row">
					<form onSubmit={getUser}>
						<FormField placeholder="Username" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
						<Button buttonType="invite" type="submit" disabled={!searchValue}>Invite</Button>
					</form>
				</div>
				<div>
					<ErrorText>{inviteError}</ErrorText>
				</div>
			</Box>

			{/* Listing */}
			<div className="invited-listing">
				{selectedUsers.length > 0 ?
					selectedUsers.map((user, index) => (
						<div className="invited-user" key={index}>
							<div className="user-info">
								<span className="placeholder" />
								<span className="username">{user.username}</span>
							</div>

							<div className="actions">
								<div className="custom-select">
									<div className="current" onClick={() => togglePermsDrop(index)}>
										<Icon iconName={selectedUsers[index].write ? 'edit' : 'eye'} width="30" height="30" />
									</div>

									{permIndex === index && (
										<div className="options">
											<Icon iconName="eye" width="30" height="30" onClick={() => onChangePerms('read', index)} />
											<Icon iconName="edit" width="30" height="30" onClick={() => onChangePerms('write', index)} />
										</div>
									)}
								</div>

								<Icon iconName="cross" width="30" height="30" onClick={() => removeUser(index)} />
							</div>
						</div>
					)) : (
						<div className="no-invites">
							<span>No one has been invited yet...</span>
						</div>
					)
				}
			</div>
		</Box>
	)
}

export default InviteUsers