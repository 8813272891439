import styled from 'styled-components'
import { Text } from 'grommet'

const PlainButton = styled(Text)`
	font-size: 16px;
	margin-top: 0.5em;
	text-align: center;
	color: ${props => props.color ? props.color : '#fff'};
	transition: 0.2s;
	&:hover {
		cursor: pointer;
		color: #fff;
		transition: 0.2s;
	}
`

export default PlainButton