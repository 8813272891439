import React, { useState } from 'react'
import './style.scss'
import Icon from '../Icon'

const Select = ({ options, set }) => {
	const [ showOptions, setShowOptions ] = useState(false)
	const [ selected, setSelected ] = useState({ val: 'Private', icon: 'privateLock' })

	const onChange = option => {
		set(option)
		setSelected(option)
		setShowOptions(false)
	}

	return (
		<div className="select-container">
			<div className="current-selection" onClick={() => setShowOptions(!showOptions)}>
				<Icon iconName={selected.icon} width="24" height="24" />
				<span>{selected.val}</span>
			</div>
			{showOptions && (
				<div className="select-options">
					{options.map((option, index) => (
						<div className="select-option" key={index} onClick={() => onChange(option)}>
							<span>{option.val}</span>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default Select