import * as SockJS from 'sockjs-client';
// import { config } from './../config';
const user = localStorage.getItem('user');
const sock = new SockJS('https://api.tabulara.org/echo');


const socket = () => {
  return {
    open(documentId, setLiveContent, setUsers, setDocument, setLoading, setError) {
      sock.onopen = function() {
          console.log('opened connection on document ' + documentId);

          const userToken = JSON.parse(user)

          const loadDoc = {
            type: 'LOAD_DOCUMENT',
            documentId: documentId,
            token: userToken
          }

          sock.send(JSON.stringify(loadDoc));
      };

      sock.onmessage = function(e) {
        const message = JSON.parse(e.data);
        console.log(message)

        if (message.type === 'LOADED_DOCUMENT') {
          setDocument(message.document)
          setLoading(false)
        }

        if (message.type === 'NOT_FOUND') {

        }

        if (message.type === 'USER_ONLINE') {
          setUsers(message.users);
        }


        if (message.type === 'SET_LIVE_CONTENT') {
          setLiveContent(message.content)
        }

      };

      sock.onclose = function() {
          console.log('close');
          setLoading(true);
          setError(true)
      };
    },
    send(text, documentId) {
      sock.send(JSON.stringify({
        type: 'UPDATE_CONTENT',
        documentId,
        text
      }));
    }
  }
}

const realTimeDocs = socket();
export default realTimeDocs;
