const userReducer = (state, action) => {

    switch (action.type) {
      case 'LOGIN':
        return {
          ...state,
          loggedIn: action.setUser.loggedIn,
          name: {
            first: action.setUser.name.first,
            last: action.setUser.name.last
          },
          id: action.setUser.id,
          username: action.setUser.username

        };

      default:
        return state;
    }
  };

  export default userReducer
