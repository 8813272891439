import React from 'react'
import { Box, Text } from 'grommet'
import { Link } from 'react-router-dom'
import Icon from '../Icon/index';

const HINT_HEIGHT = '18px'

const renderSwitch = type => {
	switch(type) {
		default:
		case 'back':
			return <Icon iconName="arrowLeft" width="30" height="30" />
		case 'cross':
			return <Icon iconName="cross" width="30" height="30" />
	}
}

const DocsCrown = ({ type, link, hint }) => {
	return (
		<Box width="100vw">
			<Box width={{ max: '1.5em' }}>
				<Link to={link}>
					{renderSwitch(type)}
				</Link>
			</Box>
			{hint && (
				<Box height={HINT_HEIGHT}>
					<Text color="dark-3" size="small" textAlign="center">{hint}</Text>
				</Box>
			)}
		</Box>
	)
}

export default DocsCrown
