import React from 'react'
import { useStateValue } from '../../../utils/state/state';

import './style.css'
import Markdown from 'markdown-it';
const md = new Markdown();

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const PreviewTab = () => {
  const [{ docs }, dispatch] = useStateValue();

  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const textAreaHeight = windowDimensions.height - 145;

  let markdown;
  if (docs.liveContent) {
    markdown = md.render(docs.liveContent)
  } else {
    markdown = md.render(docs.currentDocument.content);
  }

  return (
    <div className="previewTab">
      <div className="preivewContent" style={{ height: textAreaHeight + 'px' }} dangerouslySetInnerHTML={{__html:markdown}} />
    </div>
  )
}

export default PreviewTab;
