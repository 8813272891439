import React from 'react'
import { useStateValue } from '../../../utils/state/state';
import './style.css'

const EditorTabs = () => {
  const [{ docs }, dispatch] = useStateValue();
  const [activeTab, setActiveTab] = React.useState('Preview')
  const [write, setWrite] = React.useState('')
  const [preview, setPreview] = React.useState('#313131')
  console.log(docs)

  const changeTab = (tab) => {
    dispatch({
      type: 'SWITCH_EDITOR_TAB',
      tab,
    })
    setActiveTab(tab)
    if (tab === 'Write') {
      setWrite('#313131');
      setPreview('');
    } else if (tab === 'Preview') {
      setPreview('#313131');
      setWrite('');
    }
  }



  return (
    <div className="editorTabs">
      <button onClick={() => changeTab('Write')} style={{ backgroundColor: write }}>Write</button>
      <button onClick={() => changeTab('Preview')} style={{ backgroundColor: preview }}>Preview</button>
    </div>
  )
}

export default EditorTabs;
