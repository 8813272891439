import React from 'react'
import { Box, Heading, Image } from 'grommet'

const Logo = () => (
	<Box align="center" gap="small">
		<Image width="65px" src="logo500transtight.png" />
		<Heading level={2} margin={{ vertical: "none" }}>U</Heading>
	</Box>
)

export default Logo