import React, { useState } from 'react'
import { useStateValue } from '../../../utils/state/state';
import { Box, ResponsiveContext } from 'grommet'
import Nav from '../../presentations/Nav'
import Me from '../Me'
import Apps from '../Apps'

const Panel = () => {
  const [ currentTab, setCurrentTab ] = useState('Me')
  const [{ user }, dispatch] = useStateValue();
  const size = React.useContext(ResponsiveContext);

  const TabSwitch = tab => {
    switch (tab) {
      default:
      case 'Me':
        return <Me user={user}/>
      case 'Devices':
        return <Box>Lmao</Box>
      case 'Apps':
        return <Apps />
    }
  }

  return (
    <Box direction={size === 'small' ? 'column-reverse' : 'row'}>
      <Nav tabState={{ currentTab, setCurrentTab }} windowSize={size} />
      <Box margin={{ horizontal: size === 'small' ? 'large' : 'medium' }}>
        {user.loggedIn && TabSwitch(currentTab)}
      </Box>

      {/*{!user.loggedIn &&*/}
      {/*  <Box pad="small" background="status-critical" round={{ corner: 'bottom', size: 'small' }} align="center">*/}
      {/*    <Text size="small">Failed to reach server</Text>*/}
      {/*  </Box>*/}
      {/*}*/}
    </Box>
  )
}

export default Panel;
