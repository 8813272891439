import React from 'react'
import { Box, Heading, ResponsiveContext } from 'grommet'
import Button from '../../presentations/Button'

const Me = ({ user }) => {
	const isMobile = React.useContext(ResponsiveContext) === 'small'

	const logout = () => {
		window.location.replace('/login')
		localStorage.removeItem('user')
	}

	return (
		<Box>
			<Heading level={2}>Welcome back, {user.name.first || 'User'}</Heading>
			{isMobile && <Button buttonType="primary" onClick={() => logout()}>Logout</Button>}
		</Box>
	)
}

export default Me