import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { UserLogin } from '../../../utils/API/AuthApi'
import Button from '../../presentations/Button'
import FormField from '../../presentations/FormField'
import Logo from '../../presentations/Logo'
import ErrorText from '../../presentations/ErrorText'
import SuccessText from '../../presentations/SuccessText'
import Loading from '../../presentations/Loading'
import './style.scss'

const defaultValue = { username: '', password: '' }

const Login = () => {
	const [ formValue, setFormValue] = useState(defaultValue)
	const [ statusValue, setStatusValue ] = useState({ error: false, message: '' })
	const [ loadingValue, setLoadingValue ] = useState(false)

	const updateForm = e => {
		setFormValue({ ...formValue, [e.target.name]: e.target.value })
	}

	const onSubmit = async e => {
		e.preventDefault()
		setLoadingValue(true)
		const loginRes = await UserLogin(formValue)
		setStatusValue({ error: loginRes.error, message: loginRes.message })
		setLoadingValue(false)
	}

	return (
		<div className="login-container">
			<div className="logo-container">
				<Logo />
			</div>

			<div className="loading-spinner">
				{loadingValue && <Loading/>}
			</div>

			<form className="login-form" onSubmit={onSubmit}>
				<div className="fields">
					<FormField placeholder="Username" name="username" value={formValue.username} onChange={updateForm} />
					<FormField placeholder="Password" type="password" name="password" value={formValue.password} onChange={updateForm} />
				</div>

				<div className="status-message">
					{statusValue.error
						? <ErrorText>{statusValue.message}</ErrorText>
						: <SuccessText>{statusValue.message}</SuccessText>
					}
				</div>

				<div className="login-button">
					<Button buttonType="primary" type="submit">Login</Button>
				</div>
			</form>

			<div className="activation-link">
				<Link to="/activate">Enter an invite code</Link>
			</div>
		</div>
	)
}

export default Login
