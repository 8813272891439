import React from 'react';
import './style.css';

const ErrorBanner = ({ errorText }) => {
  return (
    <div className="errorBanner">
      <p>{ errorText }</p>
    </div>
  )
}

export default ErrorBanner;
