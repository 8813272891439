import React from 'react'
import { Box } from 'grommet'
import styled from 'styled-components'

const ModalBack = styled(Box)`
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #363636;
	opacity: 0.6;
`

const ModalBox = styled(Box)`
	z-index: 999;
	text-align: center;
	position: fixed;
	bottom: 1em;
	width: 80%;
	background-color: #3C3C3C;
`

const BottomModal = ({ children, set }) => {
	return (
			<Box>
				<ModalBack onClick={() => set(false)} />
				<ModalBox
					round="small"
					alignSelf="center"
					animation={{ type: 'slideUp', size: 'large', duration: 200 }}>
					{children}
				</ModalBox>
			</Box>
	)
}

export default BottomModal