import React from 'react';
import './style.css'

const Icon = ({ iconName, width, height, ...props }) => {
  const iconPath = `/images/${iconName}.svg`
  return (
    <img src={ iconPath } width={ width } height={ height } {...props} />
  )
}

export default Icon;
