import React from 'react'
import { Box, Heading } from 'grommet'
import Button from '../../../presentations/Button'
import PlainButton from '../../../presentations/PlainButton'

const ShareConfirmation = () => {
	const someFunction = () => {
		console.log('Would you look at that')
	}

	return (
		<Box pad="medium" align="center" justify="center" height="80vh">
			{/*	Hand Icon */}
			<Box round="full" background="light-1" width="80px">
				<img src="https://img.icons8.com/ios/80/000000/private.png" alt="halt" />
			</Box>

			<Box>
				<Heading level={2} textAlign="center" margin={{ bottom: 'xlarge' }}>Are you sure you want to share this document?</Heading>
			</Box>

			<Box align="center" width="60%" gap="medium">
				<Button buttonType="primary" label="I'm sure" onClick={someFunction} />
				<PlainButton>No, I want this document to remain private</PlainButton>
			</Box>
		</Box>
	)
}

export default ShareConfirmation