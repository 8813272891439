import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DocsCrown from '../../../presentations/DocsCrown'
import { GetDir, GetDirs, GetDocs, GetSharedDirs, GetSharedDocs } from '../../../../utils/API/DocsApi'
import BottomModal from '../../../presentations/BottomModal'
import Loading from '../../../presentations/Loading'
import DirectoryHeader from '../../../presentations/DirectoryHeader'
import Icon from '../../../presentations/Icon'
import './style.scss'

const modalOptions = [
	{ action: 'New Folder', state: 'folder' },
	{ action: 'New Document', state: 'document' },
	{ action: 'Upload File', state: 'folder' },
]

const Directory = () => {
	let { directoryId } = useParams();
	const [ privType, setPrivType ] = useState('')
	const [ loading, setLoading ] = useState(true)
	const [ modalShow, setModalShow ] = useState(false)
	const [ backLink, setBackLink ] = useState('')
	const [ data, setData ] = useState({ directories: [], documents: [] })
	const [ currentDirectory, setCurrentDirectory ] = useState({ name: 'N/A', id: '', parentId: '', sharedWith: [] })

	const getData = async () => {
		setLoading(true)

		const dir = await GetDir(directoryId)
		const dirs = await GetDirs(directoryId)
		const docs = await GetDocs(directoryId)
		const sharedDirs = await GetSharedDirs(directoryId)
		const sharedDocs = await GetSharedDocs(directoryId)

		Promise.all([dir, dirs, docs, sharedDirs, sharedDocs]).then(res => {
			setLoading(false)
			setCurrentDirectory(res[0].directory)

			if (res[0].directory.sharedWith.length > 0) {
				setPrivType('Shared')
			} else {
				setPrivType('Private')
			}

			if (res[0].directory.parentId === '') {
				setBackLink('/docs')
			} else {
				setBackLink(`/directory/${res[0].directory.parentId}`)
			}

			setData({ directories: res[1].directories.concat(res[3].directories), documents: res[2].documents.concat(res[4].documents) })
		})
	}

	useEffect(() => {
		if (currentDirectory.id !== directoryId) {
			getData()
		}
	})

	const handleCloseModal = () => {
		setModalShow(false)
	}

	const addItem = () => {
		setModalShow(true)
	}

	return (
		<div className="directory-container">
			<DocsCrown type="back" link={backLink} hint={privType.toUpperCase()} />
			{loading
				? <Loading />
				: (
					<div className="directory-content">
						<div>
							<DirectoryHeader dirName={currentDirectory.name} plusClick={addItem} />
						</div>

						<div className="list-container">
							{Object.keys(data).map(type => (
								data[type].map(item => (
									<a href={type === 'directories' ? item.id : `/document/${item.id}`} key={item.id}>
									 	<div className="dir-list-item">
									 		<Icon iconName={type === 'directories' ? 'folder' : 'document'} width="24" height="24" />
									 		<span>{item.name}</span>
									 	</div>
									</a>
								))
							))}

							{data.directories.length === 0 && data.documents.length === 0 && (
								<div className="no-files">
									<span>No files here...</span>	
								</div>
							)}
						</div>
					</div>
				)
			}

			{modalShow &&
				<BottomModal set={handleCloseModal}>
					<div className="modal">
						{modalOptions.map((option, index) => (
							<div className="modal-option" key={index}>
								{index !== 0 && <span className="modal-line" />}
								<Link to={{ pathname: `/${option.state}/new`, search: `?parentId=${currentDirectory.id}`, state: { item: option.state, privType } }}>
									<div className="modal-option-detail">
										<span>{option.action}</span>
									</div>
								</Link>
							</div>
						))}
					</div>
				</BottomModal>
			}
		</div>
	)
}

export default Directory
