import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import realTimeDocs from './../../../utils/API/RealtimeHandler'
import Loading from './../../presentations/Loading/'
import EditorTabs from './../../presentations/EditorTabs'
import DocumentHeader from './../../presentations/DocumentHeader'
import OnlineUsers from './../../presentations/OnlineUsers'
import PreviewTab from './../../presentations/PreviewTab'
import WriteTab from './../../presentations/WriteTab'
import ErrorBanner from './../../presentations/ErrorBanner'
import ViewOnlyBanner from './../../presentations/ViewOnlyBanner'
import { useStateValue } from '../../../utils/state/state'
import './style.scss'
import { GetDoc } from '../../../utils/API/DocsApi'


const Editor = () => {
  const [error, setError] = React.useState(false)
  const [{ docs }, dispatch] = useStateValue()
  const [loading, setLoading] = React.useState(true)
  const [parentUrl, setParentUrl] = React.useState('')
  let { documentId } = useParams()

  console.log(docs)

  const setLiveContent = (liveContent) => {
    dispatch({
      type: 'SET_LIVE_CONTENT',
      liveContent,
    })
  }

  const setDocument = (document) => {
    dispatch({
      type: 'LOAD_DOCUMENT',
      document
    })
  }

  const setOnlineUsers = (onlineUsers) => {
    dispatch({
      type: 'SET_ONLINE_USERS',
      onlineUsers
    })
  }

  realTimeDocs.open(documentId, setLiveContent, setOnlineUsers, setDocument, setLoading, setError)

  const sendType = (value) => {
    realTimeDocs.send(value, documentId)
  }

  useEffect(() => {
    (async () => {
      const res = await GetDoc(documentId)
      if (res.document.parentId) {
        setParentUrl(`/directory/${res.document.parentId}`)
      }
    })()
  })

  return (
    <div className="editor">
    { error && (
      <ErrorBanner errorText="Lost connection with socket, please refresh"/>
    )}

      { loading && (
        <Loading />
      )}


      { !loading && (
        <>
          <DocumentHeader documentName={ docs.currentDocument.name } privacy={ docs.currentDocument.sharedWith } parentDir={parentUrl} />

          { ( docs.currentDocument.permission === 'write' ) && (
            <EditorTabs />
          )}

          { ( docs.currentDocument.permission === 'read' ) && (
            <ViewOnlyBanner />
          )}

          <OnlineUsers />

          { (docs.tab === 'Preview' || !docs.tab) && (
            <PreviewTab />
          )}

          { docs.tab === 'Write' && (
            <WriteTab sendType={ sendType } />
          )}

        </>
      )}
    </div>
  )
}

export default Editor;
