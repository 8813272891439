import React from 'react'
import Icon from './../Icon'
import { useStateValue } from '../../../utils/state/state';
import './style.css'

const OnlineUsers = () => {
  const [{ docs }, dispatch] = useStateValue();
  let onlineUsers = docs.onlineUsers ? docs.onlineUsers : [];

  return (
    <>
      { onlineUsers.length > 1 && (
        <div className="onlineUsers">
          { onlineUsers.map(onlineUser => {
            return <div key={ onlineUser.id }><p> { onlineUser.username } </p></div>
          })}
        </div>
      )}
    </>
  )
}

export default OnlineUsers;
