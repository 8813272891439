import Icon from './../Icon'
import React from 'react';
import './style.css';

const ViewOnlyBanner = () => {
  return (
    <div className="viewOnlyBanner">
      <Icon iconName="eye" width="16" height="16" /><p>View Only</p>
    </div>
  )
}

export default ViewOnlyBanner;
