import React from 'react'
import { Box, Text } from 'grommet'
import { Flag, Folder } from 'grommet-icons'
import { Link } from 'react-router-dom'

	/*
	*
	* Fix alignment issue another time
	* Wanted to get functionality in place first
	*
  */

const internalApps = [
	{ name: 'Docs', icon: <Folder size="large" />, link: '/docs' },
]
const externalApps = [
	{ name: 'Clubhouse', icon: <Flag size="large" />, link: '/docs' }
]

const AppBox = app => (
	<Link to={app.app.link}>
		<Box background="dark-1" round="small" pad="medium" margin={{ bottom: 'small' }}>
		 	{app.app.icon}
		</Box>
	</Link>
		)

const Apps = () => (
	<Box margin={{ top: 'medium' }} gap="large">
		<Box>
			<Text color="dark-3">INTERNAL</Text>
			<Box wrap direction="row" margin={{ top: 'small' }}>
				{internalApps.map((app, index) => <AppBox app={app} key={index} />)}
			</Box>
		</Box>

		<Box>
			<Text color="dark-3">EXTERNAL</Text>
			<Box wrap direction="row" margin={{ top: 'small' }}>
				{externalApps.map((app, index) => <AppBox app={app} key={index} />)}
			</Box>
		</Box>
	</Box>
)

export default Apps