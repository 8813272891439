const theme = {
	name: 'social-dark',
	global: {
		colors: {
			brand: '#e711b8',
			background: '#222222',
			'background-1': '#e7efff',

			'light-1': '#fff',
			'light-2': '#d3d3d3',
			'light-3': '#4a4a4a',

			'dark-1': '#363636',
			'dark-2': '#323030',
			'dark-3': '#696363',
			'dark-4': '#a09595',
			'dark-5': '#2d2d2d',

			'accent-1': '#fff',
			'accent-2': '#bb79c6',
			'accent-3': '#f37676',
			placeholder: '#7c7575',
			focus: '#e711b8'
		},
		// active: {
		// 	color: '#e711b8',
		// 	background: {
		// 		color: '#323030'
		// 	}
		// },
		font: {
			family: '\'graphik\', sans-serif'
		},
		input: {
			weight: undefined,
			font: {
				weight: 400
			}
		},
	},
	select: {
		// background: 'dark-2'
		// icons: {
		// 	margin: { horizontal: 'large' }
		// }
	},
	text: {
		small: {
			size: '16px'
		}
	},
	textInput: {
		extend: {
			color: '#ffffff'
		}
	}
}

export default theme
