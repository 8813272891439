import React from 'react'
import './index.css'

const Loading = () => (
	<div className="spinner-container">
		<svg fill="none" width="64" height="64" xmlns="http://www.w3.org/2000/svg">
			<circle cx="32" cy="32" r="16" />
		</svg>
	</div>
)

export default Loading