import React, { useState } from 'react'
import FormField from '../../presentations/FormField'
import Button from '../../presentations/Button'
import { CheckInvite } from '../../../utils/API/AuthApi'
import Register from '../Register'
import Logo from '../../presentations/Logo'
import { Link } from 'react-router-dom'
import Loading from '../../presentations/Loading'
import ErrorText from '../../presentations/ErrorText'
import "./style.scss"

const Activate = () => {
	const [ error, setError ] = useState(false)
	const [ loadingValue, setLoadingValue ] = useState(false)
	const [ showRegister, setShowRegister ] = useState(false)
	const [ codeValue, setCodeValue ] = useState({ inviteCode: '' })

	const onSubmit = async e => {
		e.preventDefault()
		setLoadingValue(true)
		setError(false)

		const res = await CheckInvite(codeValue)
		if (res.valid) {
			setShowRegister(true)
		} else {
			setError(true)
		}
		setLoadingValue(false)
	}

	return (
		showRegister
			? <Register inviteCode={codeValue.inviteCode} />
			: (
					<div className="activation-container">
							<div className="logo-container">
								<Logo />
							</div>

							<div className="loading-spinner">
								{loadingValue && <Loading/>}
							</div>

							<form className="activation-form" onSubmit={onSubmit}>
								<div className="fields">
									<FormField name="inviteCode" placeholder="Invite code" autoComplete="off" onChange={val => setCodeValue({ inviteCode: val.target.value })} />
								</div>

								<div className="status-message">
									{error && <ErrorText>Please enter a valid code</ErrorText>}
								</div>

								<div className="activate-button">
									<Button buttonType="primary" type="submit">Continue</Button>
								</div>
							</form>

							<div className="login-link">
								<Link to="/login">Back to Login</Link>
							</div>
					</div>
				)
	)
}

export default Activate