import React, { useEffect, useState } from 'react'
import DocsCrown from '../../presentations/DocsCrown'
import FormField from '../../presentations/FormField'
import { GetDirs, GetDocs, GetSharedDirs, GetSharedDocs } from '../../../utils/API/DocsApi'
import BottomModal from '../../presentations/BottomModal'
import Loading from '../../presentations/Loading'
import Icon from '../../presentations/Icon'
import { Link } from 'react-router-dom'
import './style.scss'

const modalOptions = [
	{ action: 'New Folder', state: 'folder' },
	{ action: 'New Document', state: 'document' },
	{ action: 'Upload File', state: 'folder' },
]
const initialData = {
	private: { directories: [], documents: [] },
	shared: { directories: [], documents: [] },
	sharedWith: { directories: [], documents: [] }
}

const Docs = () => {
	const [ initLoad, setInitLoad ] = useState(false)
	const [ searchValue, setSearchValue ] = useState('')
	const [ currentTab, setCurrentTab ] = useState('my')
	const [ data, setData ] = useState(initialData)
	const [ modalShow, setModalShow ] = useState(false)
	const [ activeBtns, setActiveBtns ] = useState({ private: false, shared: false })

	const getMyFiles = async () => {
		const privItems = { directories: [], documents: [] }
		const sharedItems = { directories: [], documents: [] }

		const dirs = await GetDirs()
		const docs = await GetDocs()

		const sortItems = (item, type) => {
			if (item.sharedWith.length > 0) {
				sharedItems[type].push(item)
			} else {
				privItems[type].push(item)
			}
		}

		// Categorises into private and shared
		dirs.directories.forEach(item => {
			sortItems(item, 'directories')
		})

		docs.documents.forEach(item => {
			sortItems(item, 'documents')
		})

		setData({
			...data,
			private: privItems,
			shared: sharedItems
		})

		setInitLoad(true)
	}

	const getSharedWithFiles = async () => {
		const sharedWithItems = { directories: [], documents: [] }
		const sharedDirs = await GetSharedDirs()  // TEST SOME PROMISE ALL SHIT HERE
		const sharedDocs = await GetSharedDocs()

		sharedWithItems.directories = sharedDirs.directories
		sharedWithItems.documents = sharedDocs.documents

		setData({ ...data, sharedWith: sharedWithItems })
	}

	useEffect(() => {
		if (!initLoad) {
			getMyFiles()
			getSharedWithFiles()
		}
	})

	const changeTab = tab => {
		// Makes appropriate calls
		tab === 'my' ? getMyFiles() : getSharedWithFiles()
		setCurrentTab(tab)
	}

	const handleCloseModal = () => {
		setModalShow(false)
		setActiveBtns({ private: false, shared: false, sharedWith: false })
	}

	return (
		<div className="docs-container">
			<DocsCrown type="back" link="/panel" />
			<div>
				{/* Header & Search */}
				<div className="heading">
					<h2>Docs</h2>
					<FormField name="search" placeholder="Search" onChange={val => setSearchValue(val.target.value)} />
				</div>


				{/* Tabs */}
				<div className="doc-tabs">
					<div className={'tab ' + (currentTab === 'my' && 'tab-active')} onClick={() => changeTab('my')}>
						<Icon iconName="duplicate" width="20" height="20" />
						<span>MY FILES</span>
					</div>

					<div className={'tab ' + (currentTab === 'shared' && 'tab-active')} onClick={() => changeTab('shared')}>
						<Icon iconName="connectedPeople" width="20" height="20" />
						<span>SHARED WITH ME</span>
					</div>
				</div>

				{/* Listing */}
				<div className="listing-container">
					{currentTab === 'my' && (
						// Private & Shared Files
						[ 'private', 'shared' ].map((type, index) => (
							<div className="subsection" key={index}>
								<div className="subsection-heading">
									<span>{type === 'private' ? `PRIVATE FILES` : `SHARED WITH OTHERS`}</span>
									<div className={'add-box ' + (activeBtns[type] && 'active-add-box')} onClick={() => setActiveBtns({ ...activeBtns, [type]: !activeBtns[type] })}>
										<Icon iconName="plusMath" width="24" height="24" onClick={() => setModalShow(!modalShow)}/>
									</div>
								</div>

								{initLoad && ['directories', 'documents'].map((fileType) => (
									data[type][fileType].map(item => (
										<a href={fileType === 'directories' ? `directory/${item.id}` : `document/${item.id}`} key={item.id}>
											<div className="doc-list-item">
												<Icon iconName={fileType === 'directories' ? 'folder' : 'document'} width="24" height="24" />
												<span>{item.name}</span>
											</div>
										</a>
									))
								))} 
								
								{!initLoad && (
									<Loading />
								)}
							</div>
						))
					)}

					{currentTab === 'shared' && (
						// Shared With Me
						<div className="subsection">
							<div className="subsection-heading shared-with-height">
									<span>SHARED WITH ME</span>
								</div>

							{initLoad && ['directories', 'documents'].map(fileType => (
								data.sharedWith[fileType].map(item => (
									<a href={fileType === 'directories' ? `directory/${item.id}` : `document/${item.id}`} key={item.id}>
										<div className="doc-list-item">
											<Icon iconName={fileType === 'directories' ? 'folder' : 'document'} width="24" height="24" />
											<span>{item.name}</span>
										</div>
									</a>
								))
							))}
							
							{!initLoad && (
								<Loading />
							)}
						</div>
					)}
				</div>
			</div>

			{/* Very work in progress */}
			{modalShow &&
				<BottomModal set={handleCloseModal}>
					<div className="modal">
						{modalOptions.map((option, index) => (
							<div className="modal-option" key={index}>
								{index !== 0 && <span className="modal-line" />}
								<Link to={{ pathname: `${option.state}/new`, state: { item: option.state } }}>
									<div className="modal-option-detail">
										<span>{option.action}</span>
									</div>
								</Link>
							</div>
						))}
					</div>
				</BottomModal>
			}
		</div>
	)
}

export default Docs
