import React from 'react'
import Login from '../Login'
import { Box } from 'grommet'
import { Link } from 'react-router-dom'

const CONTAINER_WIDTH = "450px"

const Container = ({ children }) => (
  <Box
    pad={{ vertical: 'small', horizontal: 'medium' }}
    margin="medium"
    background="background"
    width={CONTAINER_WIDTH}
    round="small"
    align="center"
    alignSelf="center"
    gap="large">
    {children}
  </Box>
)

const Test = () => {
  return (
    <div><a href="/panel">panel</a></div>
  )
}

export default Test;
