import React, { useEffect, useState } from 'react'
import FormField from '../../../presentations/FormField'
import Button from '../../../presentations/Button'
import { CreateDir, CreateDoc } from '../../../../utils/API/DocsApi'
import Loading from '../../../presentations/Loading'
import InviteUsers from '../../../presentations/InviteUsers'
import DocsCrown from '../../../presentations/DocsCrown'
import Icon from '../../../presentations/Icon'
import Select from '../../../presentations/Select'
import './style.scss'

const NewFolder = ({ location }) => {
	const selectOptions = [
		{ val: 'Private', icon: 'privateLock' },
		{ val: 'Shared', icon: 'connectedPeople' },
	]

	// console.log(window.location.href);

	const itemType = location.state.item.charAt(0).toUpperCase() + location.state.item.slice(1)

	// let itemType;
	const forceType = location.state.privType || null

	// if (window.location.href)

	const [ loading, setLoading ] = useState(false)
	const [ parentId, setParentId ] = useState('')
	const [ sharedUsersValue, setSharedUsersValue ] = useState([])
	const [ formValue, setFormValue ] = useState({ name: '', parentId: '', type: forceType })

	const onSubmit = async () => {
		const form = {
			name: formValue.name,
			parentId
		}

		if (formValue.type === 'Shared') {
			form.sharedWith = sharedUsersValue
		}

		setLoading(true)
		const created = itemType === 'Document' ? await CreateDoc(form) : await CreateDir(form)
		setLoading(false)

		if (created && (itemType === 'Folder')) {
			// Redirects to parent directory or docs home
			window.location.replace(parentId ? `/directory/${parentId}` : `/docs`)
		} else if (created && (itemType === 'Document')) {
			window.location.replace(`/document/${created.document.id}`)
		} else {
			console.log('Uh oh')
		}
	}

	const updateSharedUsers = users => {
		setSharedUsersValue(users)
	}

	const updatePrivType = option => {
		console.log(option.val)
		setFormValue({ ...formValue, type: option.val })
	}

	useEffect(() => {
		setParentId(location.search.split('?parentId=').pop())
	})

	return (
		<div className="new-container">
			<DocsCrown type="cross" link={parentId ? `/directory/${parentId}` : '/docs'} />
			<div className="new-form">
				<div className="new-heading">
					<Icon iconName={itemType || 'Folder'} width="32" height="32" />
					<h2>New {itemType || 'Folder'}</h2>
				</div>
				<div className="new-body">
					{/* Folder Name */}
					<FormField className="name-field" name="name" placeholder={itemType + ' name'} value={formValue.name} onChange={e => setFormValue({ ...formValue, name: e.target.value })} />

					{/* Type */}
					{!forceType && <Select options={selectOptions} set={updatePrivType} />}

					{/*	Invite Users */}
					{(formValue.type === 'Shared' || forceType === 'Shared') && <InviteUsers setUsers={updateSharedUsers} />}

					<div className="submit-box">
						<div className="loading-spinner">
							{loading && <Loading />}
						</div>
						<Button
							buttonType="primary"
							onClick={onSubmit}
							disabled={!formValue.name || loading}>Create</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewFolder