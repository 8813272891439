import React from 'react'
import './style.scss'
import Icon from '../Icon'

const DirectoryHeader = ({ dirName, plusClick }) => (
	<div className="directory-header">
		<div className="name">
			<h3>{dirName}</h3>
		</div>
		<div className="icons">
			<div className="icon-circle" onClick={plusClick}>
					<Icon iconName="plusMath" width="24" height="24"/>
			</div>
			<div className="icon-circle">
				<Icon iconName="menu" width="24" height="24"/>
			</div>
		</div>
	</div>
)

export default DirectoryHeader