import React from 'react'
import { Box, Image, Sidebar, Text } from 'grommet'
import Button from '../Button'
import styled from 'styled-components'
import Icon from '../Icon'

const MobileNavStyles = {
	position: 'fixed',
	bottom: 0
}

const LogoutButton = styled(Button)`
	text-align: center;
	padding: 0 1em;
`

const Nav = ({ tabState, windowSize }) => {
	const { currentTab, setCurrentTab } =  tabState
	const isMobile = windowSize === 'small'

	const tabStatuses = {
		me: currentTab === 'Me',
		devices: currentTab === 'Devices',
		apps: currentTab === 'Apps'
	}

	const tabs = [
		{
			name: 'Me',
			icon: <Icon iconName={tabStatuses.me ? 'personBrand' : 'person'} width="32" height="32" />,
			background: currentTab === 'Me' ? 'dark-2' : 'dark-1',
			border: { size: '3px', color: currentTab === 'Me' ? 'brand' : 'dark-2', side: 'left' }
		},
		// {
		// 	name: 'Devices',
		// 	icon: <Monitor color={tabStatuses.devices && 'brand'} />,
		// 	background: currentTab === 'Devices' ? 'dark-2' : 'dark-1',
		// 	border: { size: '3px', color: currentTab === 'Devices' ? 'brand' : 'dark-2', side: 'left' }
		// },
		{
			name: 'Apps',
			icon: <Icon iconName={tabStatuses.apps ? 'fourSquaresBrand' : 'fourSquares'} width="32" height="32" />,
			background: currentTab === 'Apps' ? 'dark-2' : 'dark-1',
			border: { size: '3px', color: currentTab === 'Apps' ? 'brand' : 'dark-2', side: 'left' }
		}
	]

	const logout = () => {
		window.location.replace('/login')
		localStorage.removeItem('user')
	}

	const SideHeader = () => (
		<Box align="center" pad="small">
			<Image src="logo500transtight.png" width="50px" />
		</Box>
	)

	const SideFooter = () => (
		<Box align="center" pad={{ bottom: "medium" }}>
			<LogoutButton
				onClick={() => logout()} />
		</Box>
	)

	const NavTabs = () => {
		const DesktopTabStyles = {
			pad: { horizontal: 'medium', vertical: 'small' },
			borderSelected: { side: 'left', size: '2px', color: 'brand' },
			borderUnselected: { side: 'left', size: '2px', color: 'dark-1' }
		}
		const { pad, borderSelected, borderUnselected } = DesktopTabStyles

		return (
			tabs.map((tab, index) => (
				<Box
					key={index}
					onClick={() => setCurrentTab(tab.name)}
					focusIndicator={false}
					border={!isMobile && currentTab === tab.name ? borderSelected : !isMobile && borderUnselected}>
					<Box
						align="center"
						pad={isMobile ? 'medium' : pad}
						width="large"
						background={!isMobile && currentTab === tab.name ? 'dark-2' : null}>
						{tab.icon}
						<Text color={currentTab === tab.name ? 'brand' : 'white'}>{tab.name}</Text>
					</Box>
				</Box>
			))
		)
	}

	return (
		<Box style={isMobile ? MobileNavStyles : null} direction="row">
			{isMobile ? (
				<NavTabs />
			) : (
				<Sidebar
					width="xsmall"
					height="100vh"
					background="dark-1"
					pad="none"
					gap="none"
					header={<SideHeader />}
					footer={<SideFooter />}>
					<NavTabs />
				</Sidebar>
			)}
		</Box>
	)
}

export default Nav