import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
import Panel from './components/views/Panel/'
import Activate from './components/views/Activate'
import Login from './components/views/Login'
import Docs from './components/views/Docs'
import NewFolder from './components/views/Docs/NewFolder'
import Directory from './components/views/Docs/Directory'
import ShareConfirmation from './components/views/Docs/ShareConfirmation'
import Editor from './components/views/Editor'
import Test from './components/views/Test'
import { LoadUser } from './utils/API/AuthApi'
import { useStateValue } from './utils/state/state'

const AppHandler = () => {
	const [{ user }, dispatch] = useStateValue()

	const getUser = async () => {
		const user = await LoadUser(JSON.parse(localStorage.getItem('user')))

		console.log(user)

		if (user.user) {
			dispatch({
				type: 'LOGIN',
				setUser: {
					loggedIn: true,
					name: {
						first: user?.user.name.first,
						last: user?.user.name.last,
						preferred: user?.user.name.preferred,
					},
					id: user?.user.id,
					username: user?.user.username,
				},
			})
		} else {
			localStorage.removeItem('user')
			window.location.replace('/login')
		}
	}

	useEffect(() => {
		if (!user.loggedIn) {
			const token = localStorage.getItem('user')
			if (token) {
				getUser()
			}
		} else {
			console.log(user)
		}
	})

	let lol = false

	return (
		<>
			<Router>
				<Switch>
					{/* Temp routes probably */}
					<PrivateRoute exact path='/folder/new' component={NewFolder} />
					<PrivateRoute exact path='/document/new' component={NewFolder} />
					<PrivateRoute
						exact
						path='/directory/:directoryId'
						component={Directory}
					/>
					<PrivateRoute exact path='/docs' component={Docs} />
					<PrivateRoute
						exact
						path='/areyousureaboutthat'
						component={ShareConfirmation}
					/>
					<PrivateRoute exact path='/document/:documentId' component={Editor} />
					<PrivateRoute exact path='/folder/:folderId' component={Docs} />
					{/* Temp routes probably */}
					<Route exact path='/activate' component={Activate} />
					<PrivateRoute exact path='/panel' component={Panel} />
					<Route path='/login' component={Login} />#
					<Route path='/' component={Test} />
				</Switch>
			</Router>
		</>
	)
}

export default AppHandler
