import React from 'react'
import Icon from './../Icon'
import { Link, useParams } from 'react-router-dom'
import './style.css'

const DocumentHeader = ({ documentName, privacy, parentDir }) => {
	const privacyIcon = privacy.length > 0 ? 'connectedPeople' : 'privateLock'

	return (
		<div className='documentHeader'>
			<div className='icon-left-close'>
				<div className='icon-wrapper'>
					<Link to={parentDir || '/docs'}>
						<Icon iconName='cross' width='30' height='30' />
					</Link>
				</div>
			</div>
			<div className='title'>
				<h1>{documentName}</h1>
				<Icon iconName={privacyIcon} width='16' height='16' />
			</div>
			<div className='icon-right'>
				<div className='icon-wrapper-more'>
					<Icon iconName='menu' width='30' height='30' />
				</div>
			</div>
		</div>
	)
}

export default DocumentHeader
